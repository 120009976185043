import { Icon, IconProps } from ".";

export const Alert = (props: IconProps) => {
  return (
    <Icon {...props} viewBox="0 0 30 28">
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.0591 3.09375H12.9407V4.91131H10.8825V8.54643H8.82439V12.1815H6.76622V15.8167H4.70806V19.4518H2.6499V23.0887H4.70806V24.9063H25.2917V23.0887H27.3499V19.4518H25.2917V15.8167H23.2336V12.1815H21.1754V8.54643H19.1173V4.91131H17.0591V3.09375ZM16.5072 9.41157V10.4176H17.0115V14.9401H16.5072V17.4545H16.0065V18.9608H13.9944V17.4545H13.4926V14.9401H12.9883V10.4176H13.4926V9.41157H13.9944V8.91083H16.0065V9.41157H16.5072ZM13.9944 21.9973V19.9876H16.0065V21.9973H13.9944Z"
          fill="#FB744A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.7596 0.912109H19.2404V2.72967H21.2986V6.36479H23.3568V9.99991H25.4149V13.635H27.4731V17.2701H29.5312V25.2696H27.4731V27.0871H2.52691V25.2696H0.46875V17.2701H2.52691V13.635H4.58507V9.99991H6.64323V6.36479H8.7014V2.72967H10.7596V0.912109ZM10.8826 4.91092H12.9408V3.09336H17.0592V4.91092H19.1174V8.54604H21.1755V12.1812H23.2337V15.8163H25.2918V19.4514H27.35V23.0883H25.2918V24.9059H4.70816V23.0883H2.65V19.4514H4.70816V15.8163H6.76633V12.1812H8.82449V8.54604H10.8826V4.91092ZM12.9884 14.9397H13.4927V17.4542H13.9945V18.9604H16.0066V17.4542H16.5073V14.9397H17.0116V10.4173H16.5073V9.41118H16.0066V8.91044H13.9945V9.41118H13.4927V10.4173H12.9884V14.9397ZM16.0066 19.9872H13.9945V21.9969H16.0066V19.9872Z"
          fill="#172217"
        />
      </>
    </Icon>
  );
};
