import { Icon, IconProps } from ".";

export const Truck = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 40 40" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.85578 10V14.2837H5V15.7259H12.1395V17.1395H6.42789V18.5816H10.7116V19.9952H5V21.4374H9.28368V22.851H7.85578V27.149H9.28368V28.5769H10.7116V30.0048H15.0095V28.5769H16.4374V27.149H24.9905V28.5769H26.4184V30.0048H30.7163V28.5769H32.1442V27.149H35V24.2789H33.5721V18.5674H32.1442V17.1395H30.7163V15.7116H29.2884V14.2837H25.0048V10H7.85578ZM14.9952 25.7211V28.5626H10.7258V25.7211H14.9952ZM26.4326 25.7211V28.5626H30.7021V25.7211H26.4326ZM25.0048 15.7259V19.9952H30.7021V17.1538H29.2742V15.7259H25.0048Z"
      />
    </Icon>
  );
};
