import { Icon, IconProps } from "../archive";

export const Cartridge = (props: IconProps) => {
  return (
    <Icon {...props} fill="currentcolor">
      <g>
        <path d="M11.2727 5H12.7273V6.45455H11.2727V5Z" />
        <path d="M11.2727 7.90909V6.45455H6.90909V7.90909H5.45455V9.36364H4V16.6364H5.45455V18.0909H8.36364V16.6364H15.6364V18.0909H18.5455V16.6364H20V9.36364H18.5455V7.90909H17.0909V6.45455H12.7273V7.90909H17.0909V9.36364H18.5455V16.6364H15.6364V15.1818H8.36364V16.6364H5.45455V9.36364H6.90909V7.90909H11.2727Z" />
        <path d="M8.36364 10.8182V12.2727H15.6364V10.8182H8.36364Z" />{" "}
      </g>
    </Icon>
  );
};
